<template>
    <div>
        <load-screen-component :isLoading="isLoading"></load-screen-component>
        <page-header v-if="hasCreatePermission" btnNew="ACTIONS.NEW" pageTitle="MARITAL_STATUSES.INDEX_TITLE"
                     @newType="setRegisterModal"/>
        <page-header v-if="!hasCreatePermission" pageTitle="MARITAL_STATUSES.INDEX_TITLE"/>

        <list-component :items="items.data" v-if="items && items.data" :haveDetails="false">
            <template v-slot:principal-info="{items}">
                <div class="list-primary hide-text">{{ items.iten.name }}</div>
            </template>
            <template v-slot:dropdown-options="items">
                <div class="dropdown-item pointer text-white" data-target="#customModalTwo" data-toggle="modal"
                     data-cy="edit-marital-status"
                     @click="setEditModal(items.iten)" v-if="hasUpdatePermission && hasShowPermission">
                    <i class="icon-edit mr-2 font-15"/>{{ t('ACTIONS.EDIT') }}
                </div>
                <div class="dropdown-item pointer text-white" data-cy="delete-marital-status"
                     @click="showAlert(items.iten.id)" v-if="hasDeletePermission">
                    <i class="icon-delete mr-2 font-15"/>{{ t('ACTIONS.DELETE') }}
                </div>
            </template>
        </list-component>

        <pagination-component v-if="items && items.data" :items="items" :to="'/estadosCivis'"
                              @changePage="index($event)"></pagination-component>

        <div class="card" v-if="items && !items.data">
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('GENERAL.MSG.NO_REGISTER', {name: t('MARITAL_STATUSES.MARITAL_STATUSES'), os: 'os'}) }}</h5>
                </div>
            </div>
        </div>

        <modal :titleModal="modalTitle">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <form @submit.prevent="create">
                <div class="modal-body">
                    <div class="form-group">
                        <label for="inputName" class="required">{{ t('GENERAL.NAME') }}: </label>
                        <input id="name" v-model="name" class="form-control" name="name" placeholder="Digite o nome"
                               type="text" @keyup="inputName(name)">

                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="spouse_signature_required"
                                   v-model="maritalStatus.spouse_signature_required">
                            <label class="custom-control-label" for="spouse_signature_required">{{ t('MARITAL_STATUSES.SPOUSE_SIGNATURE_REQUIRED') }}</label>
                        </div>

                        <div v-if="isNameInvalid" class="validation">{{ t(msgError) }}</div>
                    </div>
                </div>
                <div class="modal-footer2">
                    <div class="justify-content-left">
                        <h5 class="ml-2" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
                    </div>
                    <div class="ml-custom">
                        <button data-cy="marital_status_create_close" class="btn btn-secondary" data-dismiss="modal"
                                type="button">
                            {{ t('ACTIONS.CLOSE') }}
                        </button>
                        <button data-cy="marital_status_create_save" class="btn btn-primary ml-2" type="submit">
                            {{ t('ACTIONS.SAVE') }}
                        </button>
                    </div>
                </div>
            </form>
        </modal>
    </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import {defineComponent} from 'vue';
import Modal from "@/components/modal";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import Permission from '@/components/permission.vue';
import maritalStatuses from '@/services/maritalStatuses';
import pageHeader from "@/components/layouts/pageHeader";
import ListComponent from '@/components/layouts/listComponent.vue';
import PaginationComponent from "@/components/layouts/PaginationComponent";
import loadScreenComponent from '../../components/layouts/loadScreenComponent';
import {mapState} from 'vuex';

export default defineComponent({
    name: 'indexMaritalStatuses',
    mixins: [Permission, Validate],
    components: {
        Modal,
        pageHeader,
        ListComponent,
        PaginationComponent,
        loadScreenComponent
    },
    computed: {
        ...mapState({
            isLoading: 'isLoading'
        })
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data() {
        return {
            msg: '',
            id: null,
            type: {},
            items: null,
            isAlert: true,
            modalTitle: '',
            permissions: [],
            isNewType: false,
            maritalStatus: {},
            hasShowPermission: false,
            hasIndexPermission: false,
            hasCreatePermission: false,
            hasUpdatePermission: false,
            hasDeletePermission: false,
            config: {
                min: 0,
                suffix: '',
                masked: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                thousands: '.',
                disabled: false,
                allowBlank: false,
                max: 500,
                disableNegative: true,
                minimumNumberOfCharacters: 1
            }
        }
    },

    mounted() {
        this.$store.commit('changeLoading', true);
        this.index();
        this.startPermissons();
    },

    methods: {
        async index(page = 1) {
            this.$store.commit('changeLoading', true);
            await maritalStatuses.index(page).then(resp => {
                this.items = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(error => {
                this.errorMsg(error);
                this.$store.commit('changeLoading', false);
            });

            this.$store.commit('changeLoading', false);
        },

        updatePaginate(page) {
            this.updateItemsFromPaginate(page, maritalStatuses)
        },

        setEditModal(iten) {
            this.maritalStatus.id = iten.id;
            this.name = iten.name;
            this.maritalStatus.spouse_signature_required = iten.spouse_signature_required;
            this.modalTitle = 'MARITAL_STATUSES.MODAL_TITLE.EDIT';
        },

        setRegisterModal() {
            this.id = null;
            this.name = null;
            this.spouse_signature_required = false;
            this.modalTitle = 'MARITAL_STATUSES.MODAL_TITLE.ADD';
        },

        startPermissons() {
            this.hasShowPermission = this.validatePermissions('MaritalStatuses:show');
            this.hasCreatePermission = this.validatePermissions('MaritalStatuses:create');
            this.hasUpdatePermission = this.validatePermissions('MaritalStatuses:update');
        },

        showAlert(id) {
            this.$swal.fire({
                title: this.t('GENERAL.MSG.REALLY_WANT_DELETE'),

                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Excluir`,
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);

                    maritalStatuses.delete(id).then(resp => {
                        document.getElementById('closeX').click();
                        this.index();
                        this.$store.commit('changeLoading', false);
                        this.toast.success(resp.data.validations.message);

                    }).catch(error => {
                        this.$store.commit('changeLoading', false);
                        this.errorMsg(error)
                    });
                }
            });
        },

        create() {
            this.startForm();

            if (this.checkForm()) {
                this.$store.commit('changeLoading', true);
                if (this.type.id) {
                    let data = this.type
                    let value = data.charge_expense
                    if (value){
                        value = value.replace('R$ ', '');
                        value = value.replaceAll(".", "");
                        value = value.replaceAll(",", ".");
                        value = parseFloat(value).toFixed(2);
                        data.charge_expense = value;
                    }
                    if (data.charge_expense === undefined){
                        data.charge_expense = 0.00
                    }
                    maritalStatuses.update(data).then(() => {
                        document.getElementById('closeX').click();
                        this.index();
                        this.$store.commit('changeLoading', false);
                        this.toast.success(this.t('GENERAL.MSG.UPDATE_SUCCESS'));
                        this.maritalStatus = {};
                    }).catch(error => {
                        this.$store.commit('changeLoading', false);
                        this.errorMsg(error);
                    });
                } else {
                    let data = this.type
                    let value = data.charge_expense
                    if (value){
                        value = value.replace('R$ ', '');
                        value = value.replaceAll(".", "");
                        value = value.replaceAll(",", ".");
                        value = parseFloat(value).toFixed(2);
                        data.charge_expense = value;
                    }
                    if (data.charge_expense === undefined){
                        data.charge_expense = 0.00
                    }
                    maritalStatuses.create(data).then(() => {
                        document.getElementById('closeX').click();
                        this.index();
                        this.$store.commit('changeLoading', false);
                        this.toast.success(this.t('GENERAL.MSG.REGISTER_SUCCESS'));
                        this.maritalStatus = {};
                    }).catch(error => {
                        this.$store.commit('changeLoading', false);
                        this.errorMsg(error);
                    });
                }
            }

            // let charge_expense = this.charge_expense?.toString();
            // console.log(charge_expense);
            // charge_expense = parseFloat(charge_expense.replace('R$ ', ''));
            // document.getElementById('charge_expense').classList.remove('is-invalid');
            // document.getElementById('invalidChargeExpense').style.display = 'none';

        },

        checkForm() {
            this.nameValidate(this.name);

            if (this.invalid.length && this.invalid.length > 0) {
                return false;
            }
            return true;
        },

        startForm() {
            this.type = {
                id: this.maritalStatus.id,
                name: this.name,
              spouse_signature_required: this.maritalStatus.spouse_signature_required ?? false,
            }
            this.invalid = [];
        },

    }
});
</script>

<style>
.zIndex {
    z-index: 100000000000 !important;
}
</style>